@use "@angular/material" as mat;
@use "@ng-matero/extensions" as mtx;

@include mat.core();

@import "https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

@import "../../shared/src/lib/shared.scss";
@import "../../shared/src/lib/styles/material.scss";

$my-typography: mat.define-typography-config();
@include mat.typography-hierarchy($my-typography);

$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($light-theme);
@include mtx.all-component-themes($light-theme);

html,
body {
  font-family: Roboto, "Helvetica Neue", sans-serif;

  margin: 0;
  height: 100vh;
  //width: 100vw;

  box-sizing: border-box;

  .container {
    max-width: 704px;
    width: 100%;
    box-sizing: border-box;
  }
}
